<template>
    <v-col cols="12" >

      <v-row>

        <v-col cols="12" md="6" class="mb-3">
          <v-row class="mx-md-12">

            <v-col cols="12" class="pa-1 pb-2 mb-2 border-bottom-2" >
              <v-row class="ma-0 pa-0">

                <v-col cols="12" md="12" class="pa-0 d-none d-md-block">
                  <v-row class="ma-0 pa-0 pr-md-2">
                    <v-col cols="12" md="3" class="pa-1">
                      <span class="font-weight-bold"></span>
                    </v-col>
                    <v-col cols="12" md="3" class="pa-1 d-flex justify-end pe-6">
                      <span class="font-weight-bold text-center">Esfera</span>
                    </v-col>
                    <v-col cols="12" md="3" class="pa-1 d-flex justify-end pe-6">
                      <span class="font-weight-bold text-center">Cilindro</span>
                    </v-col>
                    <v-col cols="12" md="3" class="pa-1 d-flex justify-end pe-6">
                      <span class="font-weight-bold text-center">Eje (AXI)</span>
                    </v-col>
                  </v-row>
                </v-col>


                <v-col cols="12" md="3" class="pa-1 d-flex gap-1 align-center">
                  <span class="icon_title_ojo">OD</span>
                  <span class="icon_title_text font-weight-bold">Ojo derecho</span>
                </v-col>
                <v-col cols="12" md="3" class="pa-1 d-flex justify-space-between justify-md-end">
                  <span class="font-weight-bold text-center d-md-none">Esfera</span>
                  <MinSelect 
                    v-model="localMedida.medida_od_esfera" 
                    :options="resources.esferas"
                    item-label="texto"
                    :reduce="option => option.valor"
                    positionNull="bottom"
                    :focusWhen=" localMedida.medida_od_esfera == null"
                    focusedValue="0.00"
                  />
                </v-col>
                <v-col cols="12" md="3" class="pa-1 d-flex justify-space-between justify-md-end">
                  <span class="font-weight-bold text-center d-md-none">Cilindro</span>
                  <MinSelect 
                    v-model="localMedida.medida_od_cilindro" 
                    :options="resources.cilindros"
                    item-label="texto"
                    :reduce="option => option.valor"
                    @option:selected="
                      if (localMedida.medida_od_cilindro == 0 || localMedida.medida_od_cilindro === null){
                          localMedida.medida_od_eje = null;
                      }
                    "
                  />
                </v-col>
                <v-col cols="12" md="3" class="pa-1 d-flex justify-space-between justify-md-end">
                  <span class="font-weight-bold text-center d-md-none">Eje (AXI)</span>
                  <MinSelect 
                    v-model="localMedida.medida_od_eje" 
                    :options="resources.ejes"
                    item-label="texto"
                    :reduce="option => option.valor"
                    :disabled="localMedida.medida_od_cilindro == 0 || localMedida.medida_od_cilindro === null"
                  />
                </v-col>
                

                <v-col cols="12" md="3" class="pa-1 d-flex gap-1 align-center">
                  <span class="icon_title_ojo">OI</span>
                  <span class="icon_title_text font-weight-bold">Ojo izquierdo</span>
                </v-col>
                <v-col cols="12" md="3" class="pa-1 d-flex justify-space-between justify-md-end">
                  <span class="font-weight-bold text-center d-md-none">Esfera</span>
                  <MinSelect 
                    v-model="localMedida.medida_oi_esfera" 
                    :options="resources.esferas"
                    item-label="texto"
                    :reduce="option => option.valor"
                    positionNull="bottom"
                    :focusWhen=" localMedida.medida_oi_esfera == null"
                    focusedValue="0.00"
                  />
                </v-col>
                <v-col cols="12" md="3" class="pa-1 d-flex justify-space-between justify-md-end">
                  <span class="font-weight-bold text-center d-md-none">Cilindro</span>
                  <MinSelect 
                    v-model="localMedida.medida_oi_cilindro" 
                    :options="resources.cilindros"
                    item-label="texto"
                    :reduce="option => option.valor"
                    @option:selected="
                      if (localMedida.medida_oi_cilindro == 0 || localMedida.medida_oi_cilindro === null){
                          localMedida.medida_oi_eje = null;
                      }
                    "
                  />
                </v-col>
                <v-col cols="12" md="3" class="pa-1 d-flex justify-space-between justify-md-end">
                  <span class="font-weight-bold text-center d-md-none">Eje (AXI)</span>
                  <MinSelect 
                    v-model="localMedida.medida_oi_eje" 
                    :options="resources.ejes"
                    item-label="texto"
                    :reduce="option => option.valor"
                    :disabled="localMedida.medida_oi_cilindro == 0 || localMedida.medida_oi_cilindro === null"
                  />
                </v-col>

              </v-row>
            </v-col>

            <v-col cols="12" class="pa-1 d-flex justify-space-between">
              <span>Distancia interpupilar - DIP</span>
              <MinSelect 
                v-model="localMedida.medida_dip" 
                :options="resources.dips"
                item-label="texto"
                :reduce="option => option.valor"
              />
            </v-col>

            <v-col cols="12" class="pa-1 d-flex justify-space-between">
              <span>Adición</span>
              <MinSelect 
                v-model="localMedida.medida_adicion" 
                :options="resources.adiciones"
                item-label="texto"
                :reduce="option => option.valor"
              />
            </v-col>

            <v-col cols="12" class="pa-1">
              <v-text-field dense v-model="localMedida.impresion_laboratorio" hide-details class="mt-6" label="Laboratorio" ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-1">
              <v-text-field dense v-model="localMedida.impresion_luna" hide-details class="mt-6" label="Luna" ></v-text-field>
            </v-col>


          </v-row>
        </v-col>

        <v-col cols="12" md="6" class="mb-3">
          <div class="d-flex flex-column justify-center">
            <iframe :src="assetAWS('ventas/recetas_subidas/'+localMedida.medida_ruta)"
              height="300"
              frameborder="0"
              v-if="localMedida.medida_ruta && (localMedida.medida_ruta).includes('.pdf')"
              >
            </iframe>

            <ImageZoom 
              v-else
              :image="
                localMedida.medida_ruta 
                ? assetAWS('ventas/recetas_subidas/'+localMedida.medida_ruta)
                : 'https://placehold.co/300x300?text=SIN+IMAGEN'
              " 
              />
          </div>
        </v-col>


        <v-col cols="12" class="my-3 d-flex justify-end flex-column flex-md-row" style="gap:8px;">
  
            <v-btn :loading="processForm" class="" color="error" @click="$emit('cerrar')" >
              <v-icon left="">mdi-close</v-icon> Cerrar
            </v-btn>
      
            <v-btn :loading="processForm" class="" color="success" @click="modificar" >
              <v-icon left="">mdi-check-bold</v-icon> Modificar
            </v-btn>
      
            <v-btn :loading="processForm" class="" color="primary" @click="imprimir" >
              <v-icon left="">mdi-file-pdf</v-icon> Imprimir
            </v-btn>
  
        </v-col>

      </v-row>

    </v-col>
</template>

<script>
import ImageZoom from './imageZoom/ImageZoom.vue';
import MinSelect from './MinSelect.vue';


export default {
    components:{
      ImageZoom,
      MinSelect
    },
    props: {
      processForm : Boolean, 
      value: Object
    },
    data() {
        return {
            resources: {
                esferas : [],
                cilindros : [],
                ejes : [],
                adiciones : [],
                dips : [],
                anios : [],
                meses : [],
            },
            localMedidaInit: {
              idventa_detalle : null,
              producto_codigo : '',
              producto_nombre : '',
              
              idmedida           : null,
              medida_nombre      : '',
              medida_fecha       : null,
              medida_oi_esfera   : null,
              medida_od_esfera   : null,
              medida_oi_cilindro : null,
              medida_od_cilindro : null,
              medida_oi_eje      : null,
              medida_od_eje      : null,
              medida_dip         : null,
              medida_adicion     : null,
              medida_ruta        : null,

              impresion_laboratorio : null,
              impresion_luna        : null,
            },
            localMedida: {...this.localMedidaInit},
            minSelectShowed : null
        }
    },
    watch: {
        async value(newValue){
            this.setLocalMedida(newValue)
        }
    },
    methods: {
        formatFecha(fecha) {
            return fecha ? this.$moment(fecha).format("DD/MM/YYYY") : "";
        },
        modificar(){
            const newValue = {...this.localMedida}

            this.$emit('input', newValue);
            this.$emit('modificar', newValue);
        },
        imprimir(){
            const newValue = {...this.localMedida}
            this.$emit('imprimir',newValue)
        },

        setLocalMedida(newValue){
          this.localMedida = Object.assign(this.localMedidaInit, newValue);
        }
       
    },
    mounted() {
        const esferas    = [];  // -25.00  0.25  +20.00
        const cilindros  = [];  // 0.00    0.25  -8.00
        const ejes       = [];  // 0°      1     180°
        const adiciones  = [];  // +0.75   0.25  +4.00
        const distancias = [];  // 40 mm   1     80 mm

        for (let i = -25; i<=20; i += 0.25) {
          esferas.push({
            valor: i.toFixed(2),
            texto: (i>0 ? '+' : '')+i.toFixed(2)
          });

          if ( -8 <= i  && i < 0 ) {
            cilindros.push({
              valor: i.toFixed(2),
              texto: i.toFixed(2)
            });
          }

          if ( 0.75 <= i && i <= 4) {
            adiciones.push({
              valor: i.toFixed(2),
              texto: (i>0 ? '+' : '')+i.toFixed(2)
            });
          }

        }

        for (let i = 0; i <= 180; i++) {
          ejes.push({
            valor: i.toString(),
            texto: i+'°'
          });

          if ( 40 <= i && i <= 80) {
            distancias.push({
              valor: i.toString(),
              texto: i
            });
          }

        }


        this.resources.esferas    = esferas;
        this.resources.cilindros  = cilindros;
        this.resources.ejes       = ejes;
        this.resources.adiciones  = adiciones;
        this.resources.dips       = distancias;

        this.setLocalMedida(this.value);
        
        console.log(this.localMedida);
    }

}
</script>

<style>

.gap-1{
  gap: 1rem
}

.border-bottom-2{
  border-bottom: 2px solid #000;
}

.icon_title_ojo{
  width: 19pt;
  height: 19pt;
  padding: 0;
  margin: 0;
  background-color: #2b49f9;
  color:white;
  font-weight: 600;
  border-radius: 50%;
  font-size: 10pt;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  place-items: center;
}

.icon_title_text{
  font-size: 12pt;
}

.divide-x-1 > :not([hidden]) ~ :not([hidden]) {
  border-left: 1px solid;
  border-color: inherit; /* Opcional: hereda el color del contenedor */
}


.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}



@media (min-width : 991px) {
  .divide-md-x-1 > :not([hidden]) ~ :not([hidden]) {
    border-left: 1px solid;
    border-color: inherit; /* Opcional: hereda el color del contenedor */
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  
}






</style>
<template>
    <div class="btn-group-selec dropdown-hover align-items-center position-relative" :id="id" :ref="id" v-click-outside="closeDropdown">
        <button type="button" :id="'selectBtn-'+id" class="btn dropdown-toggle" :disabled="disabled" :class="{ show : showOptions == true}" @click="toggleContent()" >
            <i class="d-flex text-optimania">
                <svg v-if="showOptions == false" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m7 10l5 5l5-5"/></svg>
                <svg v-if="showOptions == true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m17 14l-5-5l-5 5"/></svg>
            </i>
            
            <span class="sr-solo">{{ optionSelected == null ? 'Selec' : optionSelected[itemLabel] }}</span>
        </button>
        <div class="dropdown-menu" v-show="showOptions" :aria-labelledby="'selectContent-'+id">
            <ul class="scroleable scroll-plomo-delgado" >

                <li class="filtro-orden" 
                    v-if="positionNull == 'top'"
                    @click="handleClick(null)" 
                    :class="{ active: localValue == null }" 
                    >
                    Sin datos
                </li>

                <li class="filtro-orden" 
                    v-for="(item,idx) in options" 
                    :key="idx" 
                    @click="handleClick(item)" 
                    :class="{ active: reduce(item) === localValue }" 
                    >
                    {{ getText(item) }}
                </li>

                <li class="filtro-orden" 
                    v-if="positionNull == 'bottom'"
                    @click="handleClick(null)" 
                    :class="{ active: localValue == null }" 
                    >
                    Sin datos
                </li>


            </ul>
        </div>
    </div>
</template>

<script>
export default {
    directives:{
        'click-outside': {
            bind(el, binding, vnode) {
                el.clickOutsideEvent = (event) => {
                if (!(el === event.target || el.contains(event.target))) {
                    binding.value(event);
                }
            };

            document.addEventListener("click", el.clickOutsideEvent);
        },
        unbind(el) {
            document.removeEventListener("click", el.clickOutsideEvent);
        },            
        }
    },
    props:{
        value:{
            type:[Number, String, null],
        },
        options: {
            type: Array,
        },
        itemLabel: {
            type: [Number,String, null],
            require: false
        },
        reduce: {
            type: Function,
            default: (option) => option
        },
        id: {
            type: [Number, String],
            default: _ => {
                return Math.random().toString(36).substr(2, 9);
            }
        },
        showedOptions:{
            type:Boolean,
            default: false
        },
        disabled:{
            type:Boolean,
            default: false
        },
        scrollProportion: {
            type: Number,
            default: 0.98
        },
        positionNull: {
            type: String,
            default: 'top'
        },
        focusWhen: {
            type: Boolean,
            default: false
        },
        focusedValue: {
            type: [Number, String, null],
            default: null
        }

    },
    data() {
        return { 
            showOptions: this.showedOptions,
            localValue: this.value,
            optionSelected: null,
        }
    },
    watch: {
        value(newVal) {
            this.localValue = newVal;
            this.optionSelected = this.options.find( item => this.reduce(item) == newVal );
        },

        showedOptions(newVal) {
            this.showOptions = newVal;
        },

    },
    methods: {
        handleClick(item) {
            const val = item ? this.reduce(item) : item;
            this.optionSelected = item;

            this.$emit('input', val);
            this.$emit('option:selected', item);

            this.showOptions = false;
        },
        getText(item) {
            return this.itemLabel ? item[this.itemLabel] : item;
        },
        toggleContent(){
            this.showOptions = !this.showOptions;
            this.$emit('select:active', this.id);

            if (this.showOptions) {
                this.scrollToActive();
            }

        },
        observarCambioEnElemento( elemento, condicion, proporcion = 0.97 ) {
            var observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (mutation.attributeName === 'class' && elemento.classList.contains('show') && condicion) {
                        const selected = elemento.querySelector('li.active');
                        elemento.querySelector('ul').scrollTop = (selected.offsetTop ?? 0) * proporcion;
                    }
                });
            });
            observer.observe(elemento, { attributes: true });
        },
        scrollToActive(){
            
            this.$nextTick(_ => {

                if (this.$refs[this.id]) {

                    let selected = document.querySelector('#'+this.id+' li.active');
                    
                    if( this.focusWhen ){
                        const list = document.querySelectorAll('#'+this.id+' li');
                        selected = Array.from(list).find( el => el.innerText === this.focusedValue );
                    }

                    const offtop = selected?.offsetTop ?? 0;
                    
                    document.querySelector('#'+this.id+' ul').scrollTop = offtop * this.scrollProportion;

                }
            });
            

        },
        closeDropdown(){
            this.showOptions = false;
        }
    },
    mounted(){
        this.optionSelected = this.options.find( item => this.reduce(item) == this.value );
    }

}
</script>

<style>
    .position-relative{
        position: relative;
    }
    
    .dropdown-menu{
        position: absolute;
        background: #fff;
        z-index: 1;
    }


    /*scroll css*/
    .scroll-plomo-delgado::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    .scroll-plomo-delgado::-webkit-scrollbar-thumb {
        background: #DFDEDE;
        border-radius: 4px;
        cursor: pointer !important;
    }
    .scroll-plomo-delgado::-webkit-scrollbar-thumb:active {
        background-color: #DFDEDE;
        cursor: pointer !important;
    }
    .scroll-plomo-delgado::-webkit-scrollbar-thumb:hover {
        background: #DFDEDE;
        cursor: pointer !important;
    }
    .scroll-plomo-delgado::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
    }
    .scroll-plomo-delgado::-webkit-scrollbar-scroll{
        background: #DFDEDE;
    }
    .scroll-plomo-delgado::-moz-scrollbar {
        width: 6px;
        height: 6px;
    }
    .scroll-plomo-delgado::-moz-scrollbar-thumb:active {
        background-color: #DFDEDE;
        cursor: pointer !important;
    }
    .scroll-plomo-delgado::-moz-scrollbar-thumb:hover {
        background: #DFDEDE;
        cursor: pointer !important;
    }
    .scroll-plomo-delgado::-ms-scrollbar {
        width: 6px;
        height: 6px;
    }
    .scroll-plomo-delgado::-ms-scrollbar-thumb:active {
        background-color: #DFDEDE;
        cursor: pointer !important;
    }
    .scroll-plomo-delgado::-ms-scrollbar-thumb:hover {
        background: #DFDEDE;
        cursor: pointer !important;
    }
    .scroll-plomo-delgado::-ms-scrollbar-scroll{
        background: #DFDEDE;
    }
    /*fin scroll css*/


    .btn-group-selec .dropdown-toggle::after{
        display: none;
    }
    .btn-group-selec .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid #323232;
        border-radius: 10px;
        padding: 5px 10px;
        font-size: 1.2rem;
        /* width: 74px; */
        width: 100px;
    }

    .btn-group-selec .dropdown-toggle.show {
        box-shadow: 0 0 5px #2b49f9;
    }

    .btn-group-selec .dropdown-toggle:disabled {
        border-color: #acacac;
        color: #acacac;
        cursor: not-allowed;
    }
    .btn-group-selec .dropdown-toggle:disabled i {
        color: #acacac;
    }

    .btn-group-selec .dropdown-toggle i {
        font-size: 10px;
    }
    .btn-group-selec .dropdown-menu {
        padding: 4px 4px 4px 0;
        border-radius: 0.7rem;
        border: 1px solid #323232;
        font-size: 0.9rem;
        min-width: auto;
        width: 100%;
    }
    .btn-group-selec .dropdown-menu .scroleable {
        max-height: 180px;
        overflow: auto;
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
    }
    .filtro-orden {
        padding: 4px 6px;
        cursor: pointer;
        white-space: nowrap;
    }
    .filtro-orden:hover{
        background: #f6f7ff;
    }
    .filtro-orden.active {
        background: #f7f7f7;
    }



    .theme--dark.v-application .dropdown-menu{
        background: #121212;
    }
    
    .theme--dark.v-application .dropdown-menu{
        background: #121212;
    }

    .filtro-orden:hover{
        color: #121212;
    }
    .filtro-orden.active {
        color: #121212;
    }

    .text-optimania{
        color: #2b49f9;
    }



</style>
<template>
    <div class="contenedor-imagen" ref="containerPreviewBox" :class="{ 'arrastre-activo' : dragging }" >
        
        <img 
            :src="image"
            alt="Imagen"
            class="imagen"
            ref="imagePreviewBox"
            :imageLeft="imagenLeft"
            :imageTop="imagenTop"
            :style="{
                transform: `scale(${1 + zoom / 10}) rotate(${girar}deg)`,
                left: `${imagenLeft}px`,
                top: `${imagenTop}px`
            }"
            @mousedown="iniciarArrastre"
            @touchstart="iniciarArrastre"
            >
            
    </div>
</template>

<script>
export default {
    props:{
        image: [String,null],
        zoom: [Number, null],
        girar: [Number, null],
    },
    data() {
        return{

            dragging: false,
            offsetX: 0,
            offsetY: 0,
            imagenLeft: '',
            imagenTop: '',
        }
    },
    methods: {
        iniciarArrastre(event) {
            this.dragging = true;
            const left = parseFloat(this.imagenLeft || 0);
            const top = parseFloat(this.imagenTop || 0);

            this.offsetX = event.clientX - left;
            this.offsetY = event.clientY - top;

            if (event.type === 'touchstart') {
                // Evento táctil
                const touch = event.touches[0] || event.changedTouches[0];
                this.offsetX = touch.clientX - left;
                this.offsetY = touch.clientY - top;
            }

            event.preventDefault();
        },

        terminarArrastre() {
            this.dragging = false;
        },

        manejarArrastre(event) {
            if (this.dragging) {

                let x = (event.clientX  - this.offsetX) ;
                let y = (event.clientY  - this.offsetY) ;

                if (event.type === 'touchmove') {
                    const touch = event.touches[0] || event.changedTouches[0];
                    x = touch.clientX - this.offsetX;
                    y = touch.clientY - this.offsetY;
                }

                this.imagenLeft = x;
                this.imagenTop = y;
            }
        }
    },
    mounted(){
        // imagen.addEventListener('mousedown', iniciarArrastre);
        document.addEventListener('mouseup', this.terminarArrastre);
        document.addEventListener('mousemove', this.manejarArrastre);


        // imagen.addEventListener('touchstart', this.iniciarArrastre);
        document.addEventListener('touchend', this.terminarArrastre);
        document.addEventListener('touchmove', this.manejarArrastre);

    }
}
</script>

<style>

.contenedor-imagen {
    overflow: hidden; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    border: 2px solid #000;

    position: relative;

    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.imagen {
    transition: transform 0.3s ease;
    max-width: 100%;
    max-height: 100%;
    
    position: absolute;
    width: 100%;
    object-fit: cover;
    transform-origin: 0 0;
    cursor: grab;

    transform-origin: 50% 50%;
}

.arrastre-activo{
    cursor: grabbing;
}
</style>
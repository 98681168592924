<template>
    <div 
        class="slider" 
        :class="position"
        @mousedown="startDrag"
        @touchstart="startDragTouch"
        @click="onClick"
        ref="slider"
    >
        <div class="slider-track"></div>
        <div 
            class="slider-handle" 
            :style="handleStyle"
            @mousedown.stop
            @touchstart.stop
        ></div>
        <span v-if="minText" class="slider-label slider-label-min" >{{ minText }}</span>
        <span v-if="maxText" class="slider-label slider-label-max" >{{ maxText }}</span>
    </div>
</template>

<script>
export default {
    name: 'BarraSimple',
    props: {
        value: {
            type: Number,
            required: true
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        position: {
            type: String,
            default: 'horizontal' // "horizontal" o "vertical"
        },
        minText: {
            type: String,
            default: ''
        },
        maxText: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dragging: false,
            handleSize: 20  // Diámetro del handle en px
        };
    },
    computed: {
        // Rango de movimiento para el centro del handle: de handleRadius a (300 - handleRadius)
        effectiveDimension() {
            return 300 - this.handleSize; // 300 - 20 = 280
        },
        handleStyle() {
            const handleRadius = this.handleSize / 2;
            if (this.position === 'vertical') {
                // En vertical, el valor mínimo debe dejar el handle en la parte inferior
                // y el valor máximo en la parte superior. Por eso se invierte la proporción.
                const fraction = (this.value - this.min) / (this.max - this.min);
                // Invertimos: 0 -> 1 y 1 -> 0
                const verticalFraction = 1 - fraction;
                // Calculamos la posición (centro) usando la propiedad top
                const pos = handleRadius + verticalFraction * this.effectiveDimension;
                return { top: `${pos}px` };
            } else {
                // Para horizontal se calcula normalmente
                const fraction = (this.value - this.min) / (this.max - this.min);
                const pos = handleRadius + fraction * this.effectiveDimension;
                return { left: `${pos}px` };
            }
        }
    },
    methods: {
        updateValueFromEvent(event) {
            const slider = this.$refs.slider;
            const rect = slider.getBoundingClientRect();
            const handleRadius = this.handleSize / 2;
            let fraction = 0;
            if (this.position === 'vertical') {
                // Para vertical: usar top y calcular la distancia desde el inicio (top) del contenedor
                let clientY = event.clientY || event.touches[0].clientY;
                let offset = clientY - rect.top; // distancia desde la parte superior
                const minPos = handleRadius;           // 10 px (handle en tope)
                const maxPos = rect.height - handleRadius; // 300 - 10 = 290 px (handle en fondo)
                // Clampeamos la posición del centro para que esté dentro del rango
                const center = Math.min(Math.max(offset, minPos), maxPos);
                // Ahora, dado que en vertical el valor máximo corresponde al tope,
                // invertimos la proporción:
                fraction = 1 - ((center - minPos) / (maxPos - minPos));
            } else {
                // Para horizontal: medir desde la izquierda
                let clientX = event.clientX || event.touches[0].clientX;
                let offset = clientX - rect.left;
                const minPos = handleRadius;
                const maxPos = rect.width - handleRadius;
                const center = Math.min(Math.max(offset, minPos), maxPos);
                fraction = (center - minPos) / (maxPos - minPos);
            }
            fraction = Math.max(0, Math.min(1, fraction));
            const newValue = this.min + fraction * (this.max - this.min);
            this.$emit('input', Number(newValue.toFixed(2)));
        },
        onMouseMove(event) {
            if (this.dragging) {
                this.updateValueFromEvent(event);
            }
        },
        onMouseUp() {
            if (this.dragging) {
                this.dragging = false;
                document.removeEventListener('mousemove', this.onMouseMove);
                document.removeEventListener('mouseup', this.onMouseUp);
            }
        },
        startDrag(event) {
            this.dragging = true;
            this.updateValueFromEvent(event);
            document.addEventListener('mousemove', this.onMouseMove);
            document.addEventListener('mouseup', this.onMouseUp);
        },
        onTouchMove(event) {
            if (this.dragging) {
                event.preventDefault();
                this.updateValueFromEvent(event);
            }
        },
        onTouchEnd() {
            if (this.dragging) {
                this.dragging = false;
                document.removeEventListener('touchmove', this.onTouchMove);
                document.removeEventListener('touchend', this.onTouchEnd);
            }
        },
        startDragTouch(event) {
            this.dragging = true;
            this.updateValueFromEvent(event);
            document.addEventListener('touchmove', this.onTouchMove, { passive: false });
            document.addEventListener('touchend', this.onTouchEnd);
        },
        onClick(event) {
            this.updateValueFromEvent(event);
        }
    }
};
</script>

<style scoped>
/* Contenedor del slider:
   Horizontal: 300px de ancho y 40px de alto.
   Vertical: 40px de ancho y 300px de alto.
*/
.slider {
    position: relative;
    cursor: pointer;
}
.slider.horizontal {
    width: 300px;
    height: 40px;
}
.slider.vertical {
    width: 40px;
    height: 300px;
}
/* Pista del slider:
   Se centra en el contenedor y se define con un grosor de 8px y color #d9defe.
*/



.slider-label {
    font-size: 14px; 
    color: #333;
}

.slider.vertical .slider-label-min {
    position: absolute;
    right: -20%;
    bottom: 0;
    
}
.slider.vertical .slider-label-max {
    position: absolute; 
    right: -20%;
    top: 0;
}

.slider.horizontal .slider-label-min {
    position: absolute;
    bottom: -20%;
    left: 0;
}
.slider.horizontal .slider-label-max {
    position: absolute; 
    bottom: -20%;
    right: 0;
}







.slider-track {
    position: absolute;
    background: #d9defe;
    border-radius: 4px;
}
.slider.horizontal .slider-track {
    top: 50%;
    left: 0;
    right: 0;
    height: 8px;
    transform: translateY(-50%);
}
.slider.vertical .slider-track {
    left: 50%;
    top: 0;
    bottom: 0;
    width: 8px;
    transform: translateX(-50%);
}
/* Handle (bolita) del slider:
   Tiene 20px de diámetro, color #2b49f9 y se posiciona de forma que su centro se mantenga dentro del área permitida.
*/
.slider-handle {
    position: absolute;
    background: #2b49f9;
    border: 2px solid #2b49f9;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -50%);
}
.slider.horizontal .slider-handle {
    top: 50%;
}
.slider.vertical .slider-handle {
    left: 50%;
}
</style>
